/* !

=========================================================
* Material Dashboard React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software. */
// @material-ui/icons
// import Dashboard from "@material-ui/icons/Dashboard";
import Payment from "@material-ui/icons/Payment";
import LibraryBooks from "@material-ui/icons/LibraryBooks";

// import BubbleChart from "@material-ui/icons/BubbleChart";
// import LocationOn from "@material-ui/icons/LocationOn";
// import Notifications from "@material-ui/icons/Notifications";
// import Unarchive from "@material-ui/icons/Unarchive";
// import Language from "@material-ui/icons/Language";
// core components/views for Admin layout
// import DashboardPage from "views/Dashboard/Dashboard.jsx";
import SendCoin from "views/SendCoin/SendCoin.jsx";
import IconAdmin from "views/IconAdmin/IconAdmin.jsx"

const dashboardRoutes = [
    {
        path: "/sendcoin",
        name: "纪念币管理",
        icon: LibraryBooks,
        component: SendCoin,
        layout: "/admin"
    },
    {
        path: "/iconadmin",
        name: "图标管理",
        icon: Payment,
        component: IconAdmin,
        layout: "/admin"
    }
];

export default dashboardRoutes;
