/*!

=========================================================
* Material Dashboard React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React,{useState,useEffect} from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from '@material-ui/core/Button';
import Search from "@material-ui/icons/Search";
import { makeStyles } from '@material-ui/core/styles';
import { useTokenContract } from 'hooks';
import { useWeb3Context } from 'web3-react';
import { isAddress } from 'utils'
// import Switch from '@material-ui/core/Switch';
import { ethers } from 'ethers'
// const GAS_MARGIN = ethers.utils.bigNumberify(1000);
const useStyles = makeStyles(theme => ({
    typo: {
      paddingLeft: "25%",
      marginBottom: "20px",
      position: "relative"
    },
    typoTwo: {
      paddingLeft: "25%",
      marginBottom: "20px",
      marginTop:"-40px",
      position: "relative"
    },
    note: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      bottom: "10px",
      color: "#00c1c2",
      display: "block",
      fontWeight: "400",
      fontSize: "18px",
      lineHeight: "18px",
      left: "0",
      marginLeft: "20px",
      position: "absolute",
      width: "260px"
    },
    noteTop: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      bottom: "10px",
      color: "#00c1c2",
      display: "block",
      fontWeight: "400",
      fontSize: "18px",
      lineHeight: "18px",
      left: "0",
      marginLeft: "20px",
      marginTop: "20px",
      position: "absolute",
      width: "260px"
    },
    cardCategoryWhite: {
      // color: "rgba(33,33,33,.99)",
      color: "white",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    cardTitleWhite: {
      color: "#FFFFFF",
      marginTop: "0px",
      minHeight: "auto",
      fontWeight: "300",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      marginBottom: "3px",
      textDecoration: "none"
   },
   addressTxt:{
       fontSize:"18px",
       width:"60%"
   },
  transferButton: {
    margin: theme.spacing(2),
    width:"10%",
    backgroundColor:'#FF8623'
  },
  buttonWrapper:{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
  },
  searchWrapper: {
    [theme.breakpoints.down("sm")]: {
      width: "-webkit-fill-available",
      margin: "10px 15px 0"
    },
    display: "inline-block"
},
  searchWrapperLeft: {
    [theme.breakpoints.down("sm")]: {
      width: "-webkit-fill-available",
      margin: "10px 15px 0px"
    },
    // marginLeft:theme.spacing(3),
    display: "inline-block"
},
arrayWrapper:{
    flexWrap:'wrap',
    wordWrap:'break-word'
}

}));

const infos_init = {
    address:'0x...',
    owner:'0x... ',
    all_supply:0
}

const values_init = {
    new_owner:'',
    recipient:'',
    coinname:'',
    token_amount:'',
    tokenId:'',
    tokenId_query:'',
    token_name:'',
    token_owner:'',
    address_query:'',
    token_array:[]
}

export default function TypographyPage() {
  const classes = useStyles();
  const [infos,setInfos] = useState(infos_init)
  const [values,setValues] = useState(values_init)
  const token_contract = useTokenContract()
  const { account } = useWeb3Context()
  const handleChange = name => event => {
      if(name === 'tokenId_query'){
          setValues({
              ...values,
              [name]: event.target.value,
              token_name:'',
              token_owner:''
          });
      }else if(name === 'address_query') {
          setValues({
              ...values,
              [name]: event.target.value,
              token_array:[]
          });
      }else {
          setValues({
              ...values,
              [name]: event.target.value
          });
      }

  };
  const changeAddress = name => async (event) => {
     //todo
     let method;
     let args;
     if(values[name] || name==='transfer'){
         args = [values[name]]
         try{
             switch (name) {
                 case 'new_owner':
                     if(!isAddress(values[name]))
                        return alert("无效的地址")
                     method = token_contract.transferMinter;
                     break;
                 case 'recipient':
                    if(!isAddress(values[name]))
                        return alert("无效的地址")
                     let {coinname,token_amount} = values
                     token_amount = + token_amount;
                     if(Number.isNaN(token_amount) || token_amount <= 0){
                         return alert("代数数量错误")
                     }
                     if(coinname){
                         method = token_contract.mintBatchWithName;
                         args = [values[name],parseInt(token_amount),coinname]
                     }else{
                         method = token_contract.mintBatch;
                         args = [values[name],parseInt(token_amount)]
                     }
                     break;
                 case 'tokenId_query':
                     let {all_supply} = infos
                     let query_id = + values[name]
                     if(Number.isNaN(query_id))
                        return alert("查询ID错误")
                     if(all_supply !== undefined && query_id > all_supply)
                        return alert("查询ID超限")
                     if(query_id <=0 ){
                         return alert("查询ID小于或等于0")
                     }
                     if(all_supply === undefined){
                         return alert("正在获取信息，请稍候")
                     }
                     setValues({
                         ...values,
                         token_name:'',
                         token_owner:''
                     })
                     let token_name = await token_contract.getNameByToken(query_id)
                     let token_owner = await token_contract.ownerOf(query_id)
                     setValues({
                         ...values,
                         token_name,
                         token_owner
                     })
                     return;
                 case 'address_query':
                     if(!isAddress(values[name]))
                        return alert("无效的地址")
                     let allTokens = await token_contract.tokensOfOwner(values[name])
                     if(allTokens.length === 0){
                         return alert("当前用户没有纪念币")
                     }else{
                         alert("当前用户纪念币数量为:" + allTokens.length)
                     }
                     setValues({
                         ...values,
                         token_array:allTokens
                     })
                     return;
                 // case 'transfer':
                 //     let tokenId = 1;
                 //     let recipient = '0x01078cAE7672Ec7268ccf416EcD08500e03E11C6'
                 //     args = [account,recipient,1];
                 //     method = token_contract.safeTransferFrom;
                 //     break;
                 default:
                     throw new Error("no method")
                 }
         }catch(err){
             console.log(err)
             return alert("合约方法错误")
         }
         method(...args,{
              gasPrice:ethers.utils.parseUnits('10.0','gwei'),
          }).then(response => {
                 alert("交易发送成功")
         }).catch(err =>{
                 console.log(err);
         });
     }else{
         alert("地址输入不能为空")
     }
  };
  useEffect(()=>{
      if(token_contract){
          async function getInfos(){
              let owner = await token_contract.minter()
              let all_supply = await token_contract.totalSupply()
              all_supply = + all_supply
              setInfos({
                  owner,
                  all_supply,
                  address:token_contract.address
              })
          }
          getInfos()
          token_contract.on('MinterTransfer',()=>{
              alert("管理员转移成功")
              getInfos()
          })
          token_contract.on('MintOneSuc',()=>{
              alert("赠送成功")
              getInfos()

          })
          token_contract.on('MintBatchSuc',()=>{
              alert("批量赠送成功")
              getInfos()
          })
      }
      return ()=>{
          if(token_contract){
              token_contract.removeAllListeners('MinterTransfer');
              token_contract.removeAllListeners('MintOneSuc');
              token_contract.removeAllListeners('MintBatchSuc');
          }
      }
  },[token_contract])
  const adminFlag = token_contract && infos.owner && account && (account.toLowerCase() === infos.owner.toLowerCase() )
  return (
   <>
   <Card>
     <CardHeader color="primary">
       <h4 className={classes.cardTitleWhite}>合约管理</h4>
       <p className={classes.cardCategoryWhite}>
         此页进行纪念币合约本身的管理
       </p>
     </CardHeader>
     <CardBody>
       <div className={classes.typo}>
         <div className={classes.note}>本合约地址为:</div>
         <h3>{infos.address}</h3>
       </div>
       <div className={classes.typo}>
         <div className={classes.note}>本合约管理员地址为:</div>
         <h3>{infos.owner}</h3>
       </div>
       <div className={classes.typoTwo}>
         <div className={classes.note} >
             <div style={{marginTop:-30}}>
                  转让管理员:
             </div>
         </div>
         <CustomInput
           formControlProps={{
             className:classes.addressTxt
           }}
           inputProps={{
             placeholder: "新管理员地址",
             inputProps: {
               "aria-label": "Transfer"
             },
             onChange:handleChange('new_owner')
           }}
         />
         <Button variant="contained"
             disabled = {!adminFlag}
             onClick={changeAddress('new_owner')} className={classes.transferButton}>
             转让
       </Button>
     </div>
     </CardBody>
   </Card>
    <Card style={{marginTop:50}}>
        <CardHeader color="primary">
          <h4 className={classes.cardTitleWhite}>纪念币数量</h4>
          <p className={classes.cardCategoryWhite}>
            此页显示纪念币发行的数量
          </p>
        </CardHeader>
         <CardBody>
             <div className={classes.typo}>
               <div className={classes.note}>已经发行的纪念币数量为:</div>
               <h3>{infos.all_supply}</h3>
             </div>
         </CardBody>
    </Card>
   <Card style={{marginTop:50}}>
       <CardHeader color="primary">
         <h4 className={classes.cardTitleWhite}>纪念币赠送</h4>
         <p className={classes.cardCategoryWhite}>
           代币名称可以为空，一次赠送最好不超过20个
         </p>
       </CardHeader>
       <CardBody>

           <div className={classes.typoTwo}>
             <div className={classes.note} >
                 <div style={{marginTop:-30}}>
                      受赠人地址:
                 </div>
             </div>
             <CustomInput
               formControlProps={{
                 className:classes.addressTxt
               }}
               inputProps={{
                 placeholder: "受益人地址",
                 inputProps: {
                   "aria-label": "SetRecipient"
                 },
                 onChange:handleChange('recipient')
               }}
             />
             </div>
             <div className={classes.typoTwo}>
               <div className={classes.note} >
                   <div style={{marginTop:-30}}>
                        纪念币名字:
                   </div>
               </div>
               <CustomInput
                 formControlProps={{
                   className:classes.addressTxt
                 }}
                 inputProps={{
                   placeholder: "纪念币名字",
                   inputProps: {
                     "aria-label": "SetCoinName"
                   },
                   onChange:handleChange('coinname')
                 }}
               />
             </div>
             <div className={classes.typoTwo}>
               <div className={classes.note} >
                   <div style={{marginTop:-30}}>
                        赠送纪念币数量:
                   </div>
               </div>
               <CustomInput
                 formControlProps={{
                   className:classes.addressTxt
                 }}
                 inputProps={{
                   placeholder: "纪念币数量",
                   inputProps: {
                     "aria-label": "Settoken_amount"
                   },
                   onChange:handleChange('token_amount')
                 }}
               />
             </div>
             <div className = {classes.buttonWrapper}>
             <Button variant="contained"
                 disabled = {!adminFlag}
                 onClick={changeAddress('recipient')} className={classes.transferButton}>
                 赠送
           </Button>
           </div>
        </CardBody>
   </Card>
   <Card style={{marginTop:50}}>
       <CardHeader color="primary">
         <h4 className={classes.cardTitleWhite}>纪念币信息查询</h4>
         <p className={classes.cardCategoryWhite}>
           这里可以查询纪念币信息和用户纪念币数量
         </p>
       </CardHeader>
       <CardBody>
           <div className={classes.typoTwo}>
             <div className={classes.note} >
                 <div style={{marginTop:-30}}>
                      根据tokenId查询:
                 </div>
             </div>
             <div className={classes.searchWrapperLeft} >
                 <CustomInput
                     formControlProps={{
                         className: classes.margin + " " + classes.search
                     }}
                     inputProps={{
                         placeholder: "tokenId",
                         inputProps: {
                             "aria-label": "tokenId"
                         },
                         onChange:handleChange('tokenId_query')
                     }}
                 />
                 <Button color="primary" aria-label="edit" onClick={changeAddress('tokenId_query')} justicon="true" round="true">
                     <Search />
                 </Button>
                 <span style={{fontSize:18,marginLeft:30}}>
                     {"ID: " + values.tokenId_query + " 名称 : " + (values.token_name ? values.token_name : "无")}
                 </span>
                 <span style={{fontSize:18,marginLeft:100}}>
                     {"所有者 : " + values.token_owner }
                 </span>
             </div>
         </div>
         <div className={classes.typoTwo}>
           <div className={classes.note} >
               <div style={{marginTop:-30}}>
                    根据用户地址查询:
               </div>
           </div>
           <div className={classes.searchWrapperLeft} >
               <CustomInput
                   formControlProps={{
                       className: classes.margin + " " + classes.search
                   }}
                   inputProps={{
                       placeholder: "0x1234....",
                       inputProps: {
                           "aria-label": "tokenId"
                       },
                       onChange:handleChange('address_query')
                   }}
               />
               <Button color="primary" aria-label="edit" onClick={changeAddress('address_query')} justicon="true" round="true">
                   <Search />
               </Button>
               <span style={{fontSize:18,marginLeft:30}}>
                   {'用户地址:' + values.address_query}
               </span>
               <span style={{fontSize:18,marginLeft:30}}>
                   {" 纪念币数量:" + values.token_array.length}
               </span>
           </div>
       </div>
       <div className={classes.typo}>
         <div className={classes.note} >
             <div >
                  具体tokenID:
             </div>
          </div>
             <div className={classes.arrayWrapper}>
                 <span style={{fontSize:18}}>
                     {(values.token_array.length > 0 ? values.token_array.join() : '无') }
                 </span>
             </div>
         </div>
         {/* <Button variant="contained"
             onClick={changeAddress('transfer')} className={classes.transferButton}>
             转让
       </Button> */}
       </CardBody>
   </Card>
   </>
  );
}

TypographyPage.propTypes = {
  classes: PropTypes.object
};
