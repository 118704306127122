import React,{useState,useEffect} from 'react';
import { useWeb3Context } from 'web3-react'
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
// import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';


function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://naturaldao.io/">
        NaturalDAO
      </Link>{' '}
      {new Date().getFullYear()}
      {'. Built with '}
      <Link color="inherit" href="https://material-ui.com/">
        Material-UI.
      </Link>
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(6, 0, 2),
  }
}));

export default function SignIn({callback}) {
  const classes = useStyles();
  const [valid,setValid] = useState(false)
  const {active,account,error} = useWeb3Context()
  function onSubmit(event){
      event.preventDefault();
      let data = new FormData(event.target)
      let email = data.get("email");
      let password = data.get("password");
      if(callback)
        callback(email,password)
  }
  useEffect(()=>{
      const flag = active && account && (!error)
      setValid(flag)
  },[active,account,error])
  return (
      <>
           <Container component="main" maxWidth="xs">
             <CssBaseline />
             <div className={classes.paper}>
               <Avatar className={classes.avatar}>
                 <LockOutlinedIcon />
               </Avatar>
               <Typography component="h1" variant="h5">
                 Sign in
               </Typography>
               <form className={classes.form} onSubmit = {onSubmit}>
                 <TextField
                   variant="outlined"
                   margin="normal"
                   required
                   fullWidth
                   id="email"
                   label="Email Address"
                   name="email"
                   autoComplete="email"
                   autoFocus
                 />
                 <TextField
                   variant="outlined"
                   margin="normal"
                   required
                   fullWidth
                   name="password"
                   label="Password"
                   type="password"
                   id="password"
                   autoComplete="current-password"
                 />
                 {/* <FormControlLabel
                   name='remember'
                   control={<Checkbox value="remember" color="primary" />}
                   label="Remember me"
                 /> */}
                 <Button
                   type="submit"
                   fullWidth
                   variant="contained"
                   color="primary"
                   disabled={!valid}
                   className={classes.submit}
                 >
                   Sign In
                 </Button>
                 {/* <Grid container>
                   <Grid item xs>
                     <Link href="#" variant="body2">
                       Forgot password?
                     </Link>
                   </Grid>
                   <Grid item>
                     <Link href="#" variant="body2">
                       {"Don't have an account? Sign Up"}
                     </Link>
                   </Grid>
                 </Grid> */}
               </form>
             </div>
             <Box mt={8}>
               <Copyright />
             </Box>
           </Container>
      </>


  );
}
