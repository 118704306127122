import React,{ Suspense } from 'react'
import SignIn from './SignIn'
// import Web3Status from './Web3Status.jsx'
import Web3ReactManager from 'Web3ReactManager'
import styled from 'styled-components'
import Header from '../Header'
const HeaderWrapper = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  width: 100%;
  justify-content: space-between;
`
const SignInWrapper = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  width: 100%;
  ${'' /* margin-top: -3rem; */}
`

export default function App({callback}) {
    return (<>
        <Suspense fallback = {null } >
          <Web3ReactManager >
              <HeaderWrapper>
                  <Header />
                  {/* <Web3Status /> */}
              </HeaderWrapper>
              <SignInWrapper>
                  <SignIn callback={callback}/>
              </SignInWrapper>
          </Web3ReactManager>
        </Suspense>
   </>
   )
}
