/* !

=========================================================
* Material Dashboard React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software. */
import React, {useState} from "react";
import ReactDOM from "react-dom";
import {createBrowserHistory} from "history";
import Web3Provider, {Connectors} from 'web3-react'
import {Router, Route, Switch, Redirect} from "react-router-dom";
import ThemeProvider from 'theme'
import Login from "login"
// import {getPathBase} from 'utils'
// core components
import Admin from "layouts/Admin.jsx";

import "assets/css/material-dashboard-react.css?v=1.7.0";


const hist = createBrowserHistory();
const {InjectedConnector, NetworkOnlyConnector} = Connectors
const Injected = new InjectedConnector({
    supportedNetworks: [Number(process.env.REACT_APP_NETWORK_ID || '1')]
})
const Network = new NetworkOnlyConnector({
    providerURL: process.env.REACT_APP_NETWORK_URL || ''
})
const connectors = {
    Injected,
    Network
}

// function ContextProviders({children}) {
//     return (<ApplicationContextProvider>
//         <AllAddressContextProvider>
//                 {children}
//         </AllAddressContextProvider>
//     </ApplicationContextProvider>)
// }
// function Updaters() {
//   return (
//     <>
//       <ApplicationContextUpdater />
//     </>
//   )
// }

function LoginPanel() {
    const [logined, setLogined] = useState(false)
    async function onLogined(_username, _password) {
        if (_username && _password && _username ==='naturaldao_admin' && _password ==='naturaldao_admin_2019'){
            setLogined(true)
        }else{
            return alert("用户名密码错误")
        }
    }
    return (<ThemeProvider>
        <> <Web3Provider connectors={connectors} libraryName="ethers.js">
            {
                logined
                    ? <MainPanel/>
                    : <Login callback={onLogined}/>
            }
        </Web3Provider>
    </>
</ThemeProvider>)
}

function MainPanel() {
    console.log(window.location.pathname)
    return (<>
    <Router history={hist} >
        <Switch>
            <Route path="/admin" component={Admin}/>
            {/* <Route path="/" component={Admin}/> */}
            <Redirect from="/" to="/admin/sendcoin"/>
        </Switch>
    </Router>
</>)
}

ReactDOM.render(<LoginPanel/>, document.getElementById("root"));
