/*!

=========================================================
* Material Dashboard React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React,{useState,useEffect} from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from '@material-ui/core/Button';
// import Search from "@material-ui/icons/Search";
import { makeStyles } from '@material-ui/core/styles';
import { useSvgContract } from 'hooks';
import { useWeb3Context } from 'web3-react';
import { calculateGasMargin,isAddress } from 'utils'
// import Switch from '@material-ui/core/Switch';
import { ethers } from 'ethers'
import { FilePicker } from 'react-file-picker'
// import ReactSVG from 'react-svg'
import svgToDataURL from 'svg-to-dataurl'

const GAS_MARGIN = ethers.utils.bigNumberify(1000);
const useStyles = makeStyles(theme => ({
    typo: {
      paddingLeft: "25%",
      marginBottom: "20px",
      position: "relative"
    },
    typoTwo: {
      paddingLeft: "25%",
      marginBottom: "20px",
      marginTop:"-40px",
      position: "relative"
    },
    typoThree: {
      paddingLeft: "25%",
      marginBottom: "20px",
      marginTop:"40px",
      position: "relative"
    },
    note: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      bottom: "10px",
      color: "#00c1c2",
      display: "block",
      fontWeight: "400",
      fontSize: "18px",
      lineHeight: "18px",
      left: "0",
      marginLeft: "20px",
      position: "absolute",
      width: "260px"
    },
    noteMiddle: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      bottom: "10px",
      color: "#000000",
      display: "block",
      fontWeight: "400",
      fontSize: "18px",
      lineHeight: "18px",
      left: "200",
      marginLeft: "20px",
      position: "absolute",
      width: "260px"
    },
    noteTop: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      bottom: "10px",
      color: "#00c1c2",
      display: "block",
      fontWeight: "400",
      fontSize: "18px",
      lineHeight: "18px",
      left: "0",
      marginLeft: "20px",
      marginTop: "20px",
      position: "absolute",
      width: "260px"
    },
    cardCategoryWhite: {
      // color: "rgba(33,33,33,.99)",
      color: "white",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    cardTitleWhite: {
      color: "#FFFFFF",
      marginTop: "0px",
      minHeight: "auto",
      fontWeight: "300",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      marginBottom: "3px",
      textDecoration: "none"
   },
   addressTxt:{
       fontSize:"18px",
       width:"60%"
   },
  transferButton: {
    margin: theme.spacing(2),
    width:"10%",
    backgroundColor:'#FF8623'
  },
  buttonWrapper:{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
  },
  searchWrapper: {
    [theme.breakpoints.down("sm")]: {
      width: "-webkit-fill-available",
      margin: "10px 15px 0"
    },
    display: "inline-block"
},
  searchWrapperLeft: {
    [theme.breakpoints.down("sm")]: {
      width: "-webkit-fill-available",
      margin: "10px 15px 0px"
    },
    marginLeft:theme.spacing(4),
    display: "inline-block"
  }

}));

const infos_init = {
    address:'0x...',
    owner:'0x... '
}

const values_init = {
    new_owner:''
}

export default function TypographyPage() {
  const classes = useStyles();
  const [infos,setInfos] = useState(infos_init)
  const [values,setValues] = useState(values_init)
  const [fileName,setFileName] = useState('')
  const [svgData,setSvgData] = useState()
  const [svgCode,setSvgCode] = useState()
  const svg_contract = useSvgContract()
  const { account } = useWeb3Context()
  const handleChange = name => event => {
      setValues({
          ...values,
          [name]: event.target.value
      });
  };
  const changeAddress = name => async (event) => {
     //todo
     let method,estimate;
     let args;
     if((values[name] && isAddress(values[name])) || name==='upload'){
         try{
             switch (name) {
                 case 'new_owner':
                     args = [values[name]]
                     method = svg_contract.transferOwner;
                     estimate = svg_contract.estimate.transferOwner;
                     break;
                 case 'upload':
                     args = [svgCode]
                     method = svg_contract.setSvg;
                     estimate = svg_contract.estimate.setSvg;
                     break;
                 default:
                     throw new Error("no method")
                 }
         }catch{
             return alert("合约方法错误")
         }
         const estimatedGasLimit = await estimate(...args);
         method(...args,{
              gasPrice:ethers.utils.parseUnits('10.0','gwei'),
              gasLimit: calculateGasMargin(estimatedGasLimit, GAS_MARGIN) }).then(response => {
                 alert("交易发送成功")
         }).catch(err =>{
                 console.log(err);
         });
     }else{
         alert("无效的地址")
     }
  };


  function getSvgFile(fileObject){
      setFileName(fileObject['name'])
      fileObject.text().then(result =>{
          setSvgCode(result)
      })
  }

  useEffect(()=>{
      if(svg_contract){
          async function getInfos(){
              let owner = await svg_contract.owner()
              setInfos({
                  owner,
                  address:svg_contract.address
              });
          }
          getInfos();
          async function getSvg(){
              let svgData = await svg_contract.getSvg()
              setSvgData(svgData)
          }
          getSvg();
          svg_contract.on('OwnerTransfer',()=>{
              alert("转让管理员成功")
              getInfos();
          });
          svg_contract.on('SetSvgSuc',()=>{
              alert("上传SVG代码成功")
              getSvg();
          });
      }
      return ()=>{
          if(svg_contract){
              svg_contract.removeAllListeners('OwnerTransfer')
              svg_contract.removeAllListeners('SetSvgSuc')
          }
      }

  },[svg_contract])

  const adminFlag = svg_contract && infos.owner && account && (account.toLowerCase() === infos.owner.toLowerCase() )

  return (
   <>
   <Card>
     <CardHeader color="primary">
       <h4 className={classes.cardTitleWhite}>合约管理</h4>
       <p className={classes.cardCategoryWhite}>
         此页进行纪念币图标存储合约本身的管理
       </p>
     </CardHeader>
     <CardBody>
       <div className={classes.typo}>
         <div className={classes.note}>本合约地址为:</div>
         <h3>{infos.address}</h3>
       </div>
       <div className={classes.typo}>
         <div className={classes.note}>本合约管理员地址为:</div>
         <h3>{infos.owner}</h3>
       </div>
       <div className={classes.typoTwo}>
         <div className={classes.note} >
             <div style={{marginTop:-30}}>
                  转让管理员:
             </div>
         </div>
         <CustomInput
           formControlProps={{
             className:classes.addressTxt
           }}
           inputProps={{
             placeholder: "新管理员地址",
             inputProps: {
               "aria-label": "Transfer"
             },
             onChange:handleChange('new_owner')
           }}
         />
         <Button variant="contained"
             disabled = {!adminFlag}
             onClick={changeAddress('new_owner')} className={classes.transferButton}>
             转让
       </Button>
     </div>
     </CardBody>
   </Card>
   <Card style={{marginTop:50}}>
       <CardHeader color="primary">
         <h4 className={classes.cardTitleWhite}>纪念币图标</h4>
         <p className={classes.cardCategoryWhite}>
            这里显示的是纪念币原图标
         </p>
       </CardHeader>
       <CardBody>
           <div className={classes.typoThree}>
             <div className={classes.note} >
                 纪念币原图标:
             </div>
             <img src={svgData ? svgToDataURL(svgData):''} alt='纪念币图标' width={200} height={200} />
           </div>
       </CardBody>
   </Card>
   <Card style={{marginTop:50}}>
       <CardHeader color="primary">
         <h4 className={classes.cardTitleWhite}>更新图标</h4>
         <p className={classes.cardCategoryWhite}>
           在本页面可以上传或者更新纪念币图标，图标格式为SVG，大小不要超过6K
         </p>
       </CardHeader>
       <CardBody>
           <div className={classes.typo}>
             <div className={classes.note} ><div style={{marginTop:-30}}>
                 SVG文件:
             </div></div>
             <div className={classes.noteMiddle} ><div style={{marginTop:-30}}>
                 {fileName}
             </div></div>

             <Button variant="contained" style={{left:500}}
               onClick = {changeAddress('upload')}
               disabled = {!adminFlag}
               className={classes.transferButton}>
               上传/更新
             </Button>
       </div>
       <div style={{marginLeft:660,marginTop:-88}}>
           <FilePicker
             extensions={['svg']}
             onChange={fileObject => getSvgFile(fileObject)}
             onError={errMsg => (console.log("error:",errMsg))}
           >
             <Button variant="contained"
               disabled = {!adminFlag}
               className={classes.transferButton}>
               选择
             </Button>
           </FilePicker>
       </div>
       <div className={classes.typoThree}>
         <div className={classes.note} >
             图片预览:
         </div>
         <img src={svgCode ? svgToDataURL(svgCode) :""} alt='SVG图片' width={200} height={200} />
        </div>
      </CardBody>
   </Card>
   </>
  );
}

TypographyPage.propTypes = {
  classes: PropTypes.object
};
