export const TOKEN_ADDRESS = {
    1: '0xe3A69aacD1B368A6407f7879da9127CEb7D34B07',
    3: '0xaB44f5171f86c8dA2BE79458182CDD51f5d66210',
    4: '0x2DC4C5aa9d6aCD58D8133560fbDAEa442E6e501D',
    42: '0x2DC4C5aa9d6aCD58D8133560fbDAEa442E6e501D',
    5777:'0xc53a1199531EaE96FdAABC4e3A1160EbffF5caeB'
}

export const IMGAE_ADDRESS = {
    1: '0x303071f72e6BC9792D303D0C1121820ECaec5a40',
    3: '0x0b3E95fe480b5feE90D30DA6CEd3f5ac1b7e3fC4',
    4: '0x2DC4C5aa9d6aCD58D8133560fbDAEa442E6e501D',
    42: '0x2DC4C5aa9d6aCD58D8133560fbDAEa442E6e501D',
    5777:'0xAe199cf5ECdc9b5Ae8AFcd7C4c93096F259179f8'
}

export const COOKIE_ID = "NaturalDao_Token"
